import Container from 'react-bootstrap/Container';
import './navbar.css'
import React, { useEffect, useRef, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../../assets/images/NewLogoTransparent.png';
import PopUps from '../Popup';

function _NavBar() {

  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedPopupId, setSelectedPopupId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const togglePopUp = () => {
    setShowPopUp(!showPopUp);
  };



  const [ clicked, setClicked ] = useState(false);
  const [currentSection, setCurrentSection] = useState("Home");

  //---------- NAVBAR SECTION INDICATOR ----------

  useEffect(() => {
    
    const handleScroll = () => {
      
      const homeSection = document.getElementById("Home");
      const productSection = document.getElementById("ProductPage");
      const solutionSection = document.getElementById("SolutionPage");
      const apiSection = document.getElementById("ApiPage");
      const integrationSection = document.getElementById("IntegrationsPage");
      const whoSection = document.getElementById("whoPage");
      const contactSection = document.getElementById("contactPage");

      if (
        window.pageYOffset >= homeSection.offsetTop &&
        window.pageYOffset < productSection.offsetTop
      ){
        setCurrentSection("Home");
      }else if (
        window.pageYOffset >= productSection.offsetTop &&
        window.pageYOffset < solutionSection.offsetTop 
      ){
        setCurrentSection("ProductPage")
      }else if(
        window.pageYOffset >= solutionSection.offsetTop &&
        window.pageYOffset < apiSection.offsetTop
      ){
        setCurrentSection("SolutionPage")
      }else if (
        window.pageYOffset >= apiSection.offsetTop &&
        window.pageYOffset < integrationSection.offsetTop
      ){  setCurrentSection("ApiPage")
      }else if (
        window.pageYOffset >= integrationSection.offsetTop &&
        window.pageYOffset < whoSection.offsetTop
      ){
        setCurrentSection("IntegrationsPage")
      }else if (
        window.pageYOffset >= whoSection.offsetTop &&
        window.pageYOffset < contactSection.offsetTop
      ){
        setCurrentSection("whoPage")
      }else if(
        window.pageYOffset >= contactSection.offsetTop
      ){
        setCurrentSection("contactPage")
      }
        
    };

    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);


  //---------- CLOSE MENU ON CLICK OUTSIDE ----------

  const navbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        document.documentElement.clientWidth <= 991
      ) {
        setClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //---------- SCROLL TO ----------

  const handleClick = () => {
    setClicked(!clicked)
  }

  const scrollToHome = () => {
    scroll.scrollTo(document.getElementById("Home").offsetTop);
  };

  const scrollToProject = () => {
    scroll.scrollTo(document.getElementById("ProductPage").offsetTop);
  }

  const scrollToSolution = () => {
    scroll.scrollTo(document.getElementById("SolutionPage").offsetTop);
  }

  const scrollToApi = () => {
    scroll.scrollTo(document.getElementById("ApiPage").offsetTop);
  };

  const scrollToIntegrations = () => {
    scroll.scrollTo(document.getElementById("IntegrationsPage").offsetTop);
  };

  const scrollToWho = () => {
    scroll.scrollTo(document.getElementById("whoPage").offsetTop);
  };

  const scrollToContact = () => {
    scroll.scrollTo(document.getElementById("contactPage").offsetTop);
  };

  const handleClickHome = () => {
    scrollToHome();
  }

  const handleClickProduct = () => {
    scrollToProject();
    setMenuOpen(false);
  };

  
  const handleShowPopup = (id) => {
    scrollToSolution();
    setSelectedPopupId(id);
    setShowPopUp(true);
  };

  const handleClosePopup = () => {
    setShowPopUp(false);
    setSelectedPopupId(null);
  };

  const handleClickApi = () => {
    scrollToApi();
    setMenuOpen(false);
  }

  const handleClickIntegrations = () => {
    scrollToIntegrations();
    setMenuOpen(false);
  }

  const handleClickWho = () => {
    scrollToWho();
    setMenuOpen(false);
  }

  const handleClickContact = () => {
    scrollToContact();
    setMenuOpen(false);
  }



  return (
    <Navbar bg="light" expand="lg" className='navbar-custom' ref={navbarRef}>
      <img onClick={handleClickHome} className='Navbar-logo' alt='' src={ Logo } />
      <Container>
        <Navbar.Toggle onClick={() => setMenuOpen(!menuOpen)} aria-controls="basic-navbar-nav" className='Toggle'/>
        <Navbar.Collapse in={menuOpen} id="basic-navbar-nav" expanded={menuOpen}>
          <Nav className="me-auto" style={{ fontSize:'75%'  }}>
            
            <Nav.Link 
              href="#home" 
              onClick={handleClickProduct}
              style={{ 
                color: '#2B2E30', 
              }} 
              className="product-link"
              aria-controls="basic-navbar-nav"
              >
                <p className={ currentSection === "ProductPage" ? "current" : "" }>PRODUCT</p>
            </Nav.Link>
            
            <NavDropdown
              className='Solution-Drop'
              title={
                <span
                  className={`SolutionNav ${currentSection === "SolutionPage" ? "current" : ""}`}
                  style={{ color: '#2B2E30', fontSize: '100%' }}
                >
                SOLUTION
              </span>}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item 
                className='Section-items'
                style={{ 'color': '#2B2E30',fontSize: '75%','text-align': 'center', 'font-weight': 'bold',backgroundColor: selectedItem === "item2" ? "green" : "initial",}} 
                onClick={() => handleShowPopup("1")}
              >
                DATA ENGINEERS
              </NavDropdown.Item>

              <NavDropdown.Item 
                className='Section-items'
                style={{ 'color': '#2B2E30',fontSize: '75%','text-align': 'center', 'font-weight': 'bold',backgroundColor: selectedItem === "item2" ? "green" : "initial",}}
                onClick={() => handleShowPopup("2")}
              >
                DEVELOPERS
              </NavDropdown.Item>

              <NavDropdown.Item 
                className='Section-items'
                style={{ 'color': '#2B2E30',fontSize: '75%','text-align': 'center', 'font-weight': 'bold',backgroundColor: selectedItem === "item2" ? "green" : "initial",}}
                onClick={() => handleShowPopup("3")}
              >
                IT ARCHITECTS
              </NavDropdown.Item>
              
              <NavDropdown.Item 
                className='Section-items'
                style={{ 'color': '#2B2E30',fontSize: '75%','text-align': 'center', 'font-weight': 'bold',backgroundColor: selectedItem === "item2" ? "green" : "initial",}}
                onClick={() => handleShowPopup("4")}
              >
                DATA SCIENTIST
              </NavDropdown.Item>
              
              <NavDropdown.Item 
                className='Section-items'
                style={{ 'color': '#2B2E30',fontSize: '75%','text-align': 'center', 'font-weight': 'bold',backgroundColor: selectedItem === "item2" ? "green" : "initial",}}
                onClick={() => handleShowPopup("5")}
              >
                EXECUTIVES
              </NavDropdown.Item>
            </NavDropdown>
            
            <Nav.Link 
              style={{ 'color': '#2B2E30', }} 
              href="#home"
              onClick={ handleClickApi } 
              className='api-nav'>
                <p className={ currentSection === "ApiPage" ? "current" : "" }>API</p>
            </Nav.Link>

            <Nav.Link 
              style={{ 'color': '#2B2E30', }} 
              href="#home"
              onClick={handleClickIntegrations} 
              className='integration-nav'>
                <p className={ currentSection === "IntegrationsPage" ? "current" : "" }>INTEGRATIONS</p>
            </Nav.Link>

            <Nav.Link 
              style={{ 'color': '#2B2E30',}} 
              href="#home"
              onClick={handleClickWho} 
              className='who-nav'>
                <p className={ currentSection === "whoPage" ? "current" : "" } style={{ 'display': 'inline' }}>WHO WE ARE</p>
            </Nav.Link>

          </Nav>
          <button className='contact-button' onClick={handleClickContact}>CONTACT</button>
        </Navbar.Collapse>
        {showPopUp && <PopUps selectedPopupId={selectedPopupId} onClose={handleClosePopup} />}
      </Container>
    </Navbar>
  );
}



export default _NavBar;