import './integrations.css';
import React, { useState } from 'react';
import { BiCaretDown, BiCaretUp  } from "react-icons/bi";
import { IoSearchSharp } from "react-icons/io5";
import IntegrationList from '../../content/IntegrationList';


const Integrations = () => {

    const [searchTerm, setSearchTerm] = useState("");
    const [showCleanButton, setShowCleanButton] = useState(false);
    const [showAllImages1, setShowAllImages1] = useState(false);
    const [showAllImages2, setShowAllImages2] = useState(false);
    const [showAllImages3, setShowAllImages3] = useState(false);
    const [showAllImages4, setShowAllImages4] = useState(false);
    const [showAllImages5, setShowAllImages5] = useState(false);
    const [showAllImages6, setShowAllImages6] = useState(false);


    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setShowCleanButton(value !== '');
    };
    
      const handleCleanSearch = () => {
        setSearchTerm('');
        setShowCleanButton(false);
    };

    const toggleImagesDataEngenieer = () => {
        setShowAllImages1(!showAllImages1);
    };

    const toggleImagesInfraestructure = () => {
        setShowAllImages2(!showAllImages2);
    };

    const toggleImagesData = () => {
        setShowAllImages3(!showAllImages3);
    };

    const toggleImagesCode = () => {
        setShowAllImages4(!showAllImages4);
    };

    const toggleImagesApp = () => {
        setShowAllImages5(!showAllImages5);
    };

    const toggleImagesTraining = () => {
        setShowAllImages6(!showAllImages6);
    };

    
    const filteredIntegrations = IntegrationList.filter((integration) => {
        const searchTermLower = searchTerm.toLowerCase();
        const integrationNameLower = integration.nombre.toLowerCase();
        return integrationNameLower.startsWith(searchTermLower) || integrationNameLower.includes(` ${searchTermLower}`);
    });


    const renderIntegrationCards = (integrations) => {
        return integrations.length ? (
        integrations.map((integration, index) => (
            <div className="IntegrationCard" key={index}>
                <img src={integration.imagen} alt={integration.nombre}/>
            </div>
        ))
        ) : (
            <div className="NotFound">
                <p>INTEGRATION <span>NOT FOUND</span></p>
            </div>
        );
    };

    

    const integrationDataEngineer = IntegrationList?.filter(integration => integration?.class === "Data Engineering")
    const visibleImagesDataE = showAllImages1 ? integrationDataEngineer : integrationDataEngineer.slice(0, 4);

    const integrationInfraestructure = IntegrationList?.filter(integration => integration?.class.includes("Infraestructure"));
const visibleImagesInfraestructure = showAllImages2 ? integrationInfraestructure : integrationInfraestructure.slice(0, 4);


    const integrationData = IntegrationList?.filter(integration => integration?.class === "Data")
    const visibleImagesData = showAllImages3 ? integrationData : integrationData.slice(0, 4);

    const integrationCode = IntegrationList?.filter(integration => integration?.class.includes("Code"));
    const visibleImagesCode = showAllImages4 ? integrationCode : integrationCode.slice(0, 4);

    const integrationApps = IntegrationList?.filter(integration => integration?.class === "APPS")
    const visibleImagesApps = showAllImages5 ? integrationApps : integrationApps.slice(0, 4);

    const integrationTraining = IntegrationList?.filter(integration => integration?.class === "Training")
    const visibleImagesTraining = showAllImages6 ? integrationTraining : integrationTraining.slice(0, 4);


    return (
        <div  className='Integrations-Container'>
            
            <div className='title-search-container'>
                
                <h2>Our <span>integrations</span></h2>

                <span className='searchbar-container'>

                    <IoSearchSharp className='searchbaricon'/>

                    <input
                        className='searchBar'
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                    />

                    {showCleanButton && (
                        <button className='cleanSearchButton' onClick={handleCleanSearch}>
                            X
                        </button>
                    )}

                </span>

            </div>


        {searchTerm ? (

        <div className='Search-Row'>

            <h2>Your Search</h2>

            <div className='YourSearchContainer'>
                {renderIntegrationCards(filteredIntegrations)}
            </div>
        </div>

        ) : (

        <div className='Row-Container'>
            
            <div className='DataEngineering-Row'>
                
                <h2>Data Engineering</h2>
               
                <div className='DataEngineerContainer'>
                    {visibleImagesDataE.map((integration, index) => {
                        return (
                            <div className='DataEngineerCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                        
                    })}
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationDataEngineer.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesDataEngenieer}>
                            {showAllImages1 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>

            <div className='Infraestructure-Row'>
                
                <h2>Infraestructure</h2>
               
                <div className='InfraestructureContainer'>
                    
                    {visibleImagesInfraestructure.map((integration, index) => {
                        return (
                            <div className='InfraestructureCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                    })}
                        
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationInfraestructure.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesInfraestructure}>
                             {showAllImages2 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>

            <div className='Data-Row'>
                
                <h2>Data</h2>
               
                <div className='DataContainer'>
                    {visibleImagesData.map((integration, index) => {
                        return (
                            <div className='DataCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                        
                    })}
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationData.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesData}>
                            {showAllImages3 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>
            
            <div className='Code-Row'>
                
                <h2>Code</h2>
               
                <div className='CodeContainer'>
                    {visibleImagesCode.map((integration, index) => {
                        return (
                            <div className='CodeCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                        
                    })}
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationCode.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesCode}>
                            {showAllImages4 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>

            <div className='Apps-Row'>
                
                <h2>APPS</h2>
               
                <div className='AppsContainer'>
                    {visibleImagesApps.map((integration, index) => {
                        return (
                            <div className='AppsCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                        
                    })}
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationApps.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesApp}>
                            {showAllImages5 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>

            <div className='Training-Row'>
                
                <h2>Training</h2>
               
                <div className='TrainingContainer'>
                    {visibleImagesTraining.map((integration, index) => {
                        return (
                            <div className='TrainingCards' key={index}>
                                <img
                                    src={integration.imagen}
                                    alt={integration.nombre}
                                />
                            </div>
                        );
                        
                    })}
                </div>
                    
                <div className='ButtonIntegrationContainer'>   
                    {integrationTraining.length > 4 && (
                        <button className='ButtonIntegration' onClick={toggleImagesTraining}>
                            {showAllImages6 ? <span>less<BiCaretUp className='arrowUpbutton'/></span> : <span>more<BiCaretDown className='arrowDownbutton'/></span>}
                        </button>
                    )}
                </div>

            </div>
        </div>

        )}

        </div>
    )
}

export default Integrations;