import "./rolescard.css"
import React, { useState } from "react";
import PopUps from '../Popup';

const RolesCards = ({role}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [selectedPopupId, setSelectedPopupId] = useState(null);
    const maxLength = 150;

    const togglePopup = (id) => {
        setSelectedPopupId(id);
        setShowPopup(!showPopup);
    };

    const truncateText = (text, maxLength) => {
        if (text && text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };

    return (
        <div className="Rolecard-container">
            <div className="Rolecard-information">
                <h3 className="executives-title">{role.title}</h3>
                <p>{truncateText(role.content, maxLength)}</p>
            </div>

            <button className="role-button" onClick={() => togglePopup(role.id)}>
                learn more
            </button>

            {showPopup && selectedPopupId === role.id && (
                <PopUps selectedPopupId={selectedPopupId} onClose={() => togglePopup(null)} />
            )}
                
        </div>
    );
};

export default RolesCards;
