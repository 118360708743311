import "./products.css";
import "./productcard.css"
import React from "react";
import ProductList from "../../content/ProductsList";



const Products = () => {
    return (
        <div id="ProductPage" className="Products-container">
            <div className="Product-title">
                <h1><span>Maximize</span> the value</h1>
                <p>
                    Unlock your data's potential with our unified platform—streamlining
                    <br/>
                    your journey from collection to actionable insights.
                </p>
            </div>

            <div className="Section-back">
                <div className="Section-container">
                    <div className="Sections-cards">
                        {ProductList.map((product, index) => (
                            <div key={index} className="Productcard-container">
                                <div className="Image-container">
                                    <img src={product.image} alt=''  style={product.size}/>
                                </div>
                                <div className="Productcard-information">
                                    <h3>{product.title}</h3>
                                    <p>{product.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;
