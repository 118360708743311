const integrationList = [
    {   
        nombre: 'Apache Airflow', 
        imagen: require("../assets/images/Integration/Airflow.png"), 
        class: 'Data Engineering' 
    },

    { 
        nombre: 'Alteryx', 
        imagen: require("../assets/images/Integration/alteryx.png"), 
        class: 'Data Engineering' 
    },

    { 
        nombre: 'Amazon S3', 
        imagen: require("../assets/images/Integration/Amazon-S3.png"), 
        class: 'Data' 
    },

    { 
        nombre: 'Amazon SageMaker', 
        imagen: require("../assets/images/Integration/Amazon-SageMaker.png"), 
        class: 'Training' 
    },
    
    { 
        nombre: 'Anaconda', 
        imagen: require("../assets/images/Integration/Anaconda.png"), 
        class: 'Code' 
    },

    { 
        nombre: 'Apache Spark MLib', 
        imagen: require("../assets/images/Integration/Apache-Spark-MLlib.png"), 
        class: 'Code' 
    },

    { 
        nombre: 'Apache Nifi', 
        imagen: require("../assets/images/Integration/Apache-Nifi.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'ArcGIS', 
        imagen: require("../assets/images/Integration/ArcGis.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Atlassian', 
        imagen: require("../assets/images/Integration/Atlassian.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Amazon Web Services', 
        imagen: require("../assets/images/Integration/AWS.png"), 
        class: 'Infraestructure' 
    },
        
    { 
        nombre: 'BigMl', 
        imagen: require("../assets/images/Integration/BigML.png"), 
        class: 'Training' 
    },
    
    { 
        nombre: 'Circleci', 
        imagen: require("../assets/images/Integration/CircleCI.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'C Sharp', 
        imagen: require("../assets/images/Integration/CSharp.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Artboard', 
        imagen: require("../assets/images/Integration/Artboard-48.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Docker', 
        imagen: require("../assets/images/Integration/Docker.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'Elastic', 
        imagen: require("../assets/images/Integration/Elastic.png"), 
        class: 'Data' 
    },
    
    { 
        nombre: 'Fastai', 
        imagen: require("../assets/images/Integration/Fast-AI.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Github Actions', 
        imagen: require("../assets/images/Integration/Githubactions.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'Go', 
        imagen: require("../assets/images/Integration/Go.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Google Cloud', 
        imagen: require("../assets/images/Integration/Google-Cloud.png"), 
        class: 'Infraestructure' 
    },
    
    { 
        nombre: 'Java', 
        imagen: require("../assets/images/Integration/Java.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Java Database Connectivity', 
        imagen: require("../assets/images/Integration/Java-JDBC.png"), 
        class: 'Data' 
    },

    { 
        nombre: 'Javascript', 
        imagen: require("../assets/images/Integration/JavaScript.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Jenkins', 
        imagen: require("../assets/images/Integration/Jenkins.png"), 
        class: 'Data Engineering'
    },
    
    { 
        nombre: 'Julia', 
        imagen: require("../assets/images/Integration/Julia.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Jupyter', 
        imagen: require("../assets/images/Integration/Jupyter.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Keras', 
        imagen: require("../assets/images/Integration/Keras.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Kubeflow', 
        imagen: require("../assets/images/Integration/Kubeflow.png"), 
        class: 'Training' 
    },
    
    { 
        nombre: 'Kubernetes', 
        imagen: require("../assets/images/Integration/Kubernetes.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'Label Studio', 
        imagen: require("../assets/images/Integration/Label-studio.png"), 
        class: 'Data Engineering'
    },
    
    { 
        nombre: 'Light GBM', 
        imagen: require("../assets/images/Integration/LightGBM.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Azure', 
        imagen: require("../assets/images/Integration/Microsoft_Azure.png"),
        class: 'Infraestructure' 
    },
    
    { 
        nombre: 'Microsoft Azure Blob Storage', 
        imagen: require("../assets/images/Integration/microsoft-azure-blob.png"), 
        class: 'Data' 
    },
    
    { 
        nombre: 'Microsoft Office', 
        imagen: require("../assets/images/Integration/Microsoft-Office.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'MlFlow', 
        imagen: require("../assets/images/Integration/MlFlow.png"), 
        class: 'Training' 
    },
    
    { 
        nombre: 'Mxnet', 
        imagen: require("../assets/images/Integration/Mxnet.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'NetApp', 
        imagen: require("../assets/images/Integration/NetApp.png"), 
        class: 'Data' 
    },
    
    { 
        nombre: 'NumPy', 
        imagen: require("../assets/images/Integration/NumPY.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Nvidia Cuda', 
        imagen: require("../assets/images/Integration/Nvidia-cuda.png"), 
        class: ['Infraestructure','Code']
    },
    
    { 
        nombre: 'Onnx', 
        imagen: require("../assets/images/Integration/ONNX.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Pandas', 
        imagen: require("../assets/images/Integration/Panda.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Python', 
        imagen: require("../assets/images/Integration/Python.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Pytorch', 
        imagen: require("../assets/images/Integration/PyTorch.png"), 
        class: 'Code' 
    },

    { 
        nombre: 'Qgis', 
        imagen: require("../assets/images/Integration/Qgis.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Qlik Q', 
        imagen: require("../assets/images/Integration/QlinkQ.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'R', 
        imagen: require("../assets/images/Integration/R.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'RStudio', 
        imagen: require("../assets/images/Integration/Rstudio.png"), 
        class: 'Data' 
    },
    
    { 
        nombre: 'Salesforce', 
        imagen: require("../assets/images/Integration/Salesforce.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'SAP', 
        imagen: require("../assets/images/Integration/SAP_Concur.png"), 
        class: 'APPS' 
    },

    { 
        nombre: 'Scala', 
        imagen: require("../assets/images/Integration/scala.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Scikit Learn', 
        imagen: require("../assets/images/Integration/SciKit-Learn.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'SignalFx', 
        imagen: require("../assets/images/Integration/SignalFX.png"), 
        class: 'Data Engineering' 
    },
    
    { 
        nombre: 'Slack', 
        imagen: require("../assets/images/Integration/slack.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Snowflake', 
        imagen: require("../assets/images/Integration/Snowflake.png"), 
        class: 'Data' 
    },
    
    { 
        nombre: 'SpaCy', 
        imagen: require("../assets/images/Integration/Spacy.png"), 
        class: 'Code' 
    },
    
    { 
        nombre: 'Splunk', 
        imagen: require("../assets/images/Integration/Splunk.png"), 
        class: 'APPS' 
    },
    
    { 
        nombre: 'Tableau', 
        imagen: require("../assets/images/Integration/Tableau.png"), 
        class: 'APPS' 
    },

    { 
        nombre: 'TenserFlow', 
        imagen: require("../assets/images/Integration/Tenserflow.png"), 
        class: 'Code' 
    },

    
    { 
        nombre: 'DMLC XGBoost', 
        imagen: require("../assets/images/Integration/XGBoost.png"), 
        class: 'Code' 
    },

  ];

  export default integrationList;