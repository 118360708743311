import DataCleaning from '../assets/images/Products/Data-Cleaning.svg';
import DataPreparation from '../assets/images/Products/Data-Preparation.svg';
import Analysis from "../assets/images/Products/Analysis.svg";
import Monitoring from '../assets/images/Products/Monitoring.png';
import IngestStorage from '../assets/images/Products/Ingest-Storage.svg';
import DataTransformation from '../assets/images/Products/Data-Transformation.svg';
import DataCollection from '../assets/images/Products/DataCollection.svg';
import Modeling from '../assets/images/Products/Modeling.svg';



const ProductList = [
    { 
        image: DataCleaning, 
        title: "DATA CLEANING", 
        content: "Remove inconsistencies, errors, and irrelevant information from datasets to ensure accuracy and reliability for analysis and modeling.",
        size: { width: "65%", marginTop: '5%', marginRight: '5%' } 
    },
    { 
        image: DataPreparation, 
        title: "DATA PREPARATION", 
        content: "Organize and structure raw data into a usable format, ready for processing and insights generation.", 
        size: { width: '50%', marginTop: '2%' }
    },
    { 
        image: DataTransformation, 
        title: "DATA TRANSFORMATION", 
        content: "Convert and modify data into the required formats or structures to make it compatible with advanced analysis and modeling systems.",
        size: { width: "70%" }  
    },
    { 
        image: IngestStorage, 
        title: "INGEST & STORAGE", 
        content: "Efficiently import and store data from multiple sources, ensuring scalability and security for long-term use.",
        size: { width: '65%' } 
    },
    { 
        image: Monitoring, 
        title: "MONITORING", 
        content: "Track and analyze data flows, system performance, and processes in real time to maintain smooth operations and detect anomalies.",
        size: { width: '75%', marginLeft: '4%' }
    },
    { 
        image: Analysis, 
        title: "ANALYSIS", 
        content: "Extract meaningful insights and trends from data, empowering businesses to make informed, data-driven decisions.",
        size: { width: '70%', marginTop: '1%', marginRight: '11%' } 
    },
    { 
        image: DataCollection, 
        title: "DATA COLLECTION", 
        content: "Capture and aggregate data from diverse sources, ensuring completeness and accuracy to support various business needs.",
        size: { width: '70%', marginBottom: '15%' }
    },
    { 
        image: Modeling, 
        title: "MODELING & PROCESSING", 
        content: "Build and optimize machine learning models and data workflows to derive actionable predictions and outcomes.",
        size: { width: '90%'}
    }
];

export default ProductList;
