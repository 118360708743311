import './apis.css';
import React from 'react';
import ApisList from '../../content/ApisList';


const Apis = () => {
    return (
        <div className='Api-Container'>
            
            <div className='Api-title'>
                <h2>Easy & accessible <span>APIs</span></h2>
                <p>
                    Unlock The Power Of Seamless Integration, Accelerated Experimentation, Scalable
                    <br/>
                    Deployment, And Comprehensive Monitoring With Our Robust API.
                </p>
            </div>

            <div className='Apis-Section'>
                {ApisList.map((api, index) => (
                    <div key={index} className='Api-type'>
                    
                        <img className='ApiImg' src={api.image} alt='' />

                        <div 
                            id={`api-${api.id}`}
                            className='Api-type1'
                            style={{
                                backgroundColor: index % 2 === 0 ? '#2B2E30' : 'rgb(255, 159, 28)'
                            }}   
                        >
                            <h3>{api.title}</h3>

                            <p>{api.content}</p>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default Apis;