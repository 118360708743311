const Rolelist = [
    {
        id: '1',
        title: "DATA ENGINEER",
        content: "Empowers data engineers by automating and optimizing data workflows, enhancing both scalability and reliability. The platform's advanced tools allow for efficient data integration, cleansing, and transformation, ensuring that data pipelines run smoothly and error-free. With built-in monitoring and analytics features, data engineers can quickly identify and resolve issues, allowing them to focus on delivering high-quality data for the organization.",
    },
    {
        id: '2',
        title: "DEVELOPER",
        content: "Simplifies data integration and pipeline management, allowing developers to focus on building scalable solutions. Its intuitive tools automate tasks and improve efficiency, ensuring seamless data access for application development.",
    },
    {
        id: '4',
        title: "DATA SCIENTIST",
        content: "Provides a unified environment where they can seamlessly experiment with, model, and deploy data-driven solutions. The platform simplifies the process of data exploration and feature engineering, enabling faster iterations and insights. With integrated machine learning tools and AI-driven features, data scientists can more effectively build, test, and deploy models, reducing the time spent on manual tasks.",
    },
    {
        id: '5',
        title: "EXECUTIVES",
        content: "Equips executives with powerful tools to make data-driven decisions quickly and confidently. The platform offers real-time data insights, visualizations, and dashboards that provide a clear understanding of key business metrics. Executives can leverage these insights to track performance, identify trends, and uncover opportunities for growth. By centralizing data and making it easily accessible, ConvolutionAI enables executives to make more informed decisions, streamline operations, and drive business outcomes with greater speed and accuracy.",
    },
];

export default Rolelist;
        
// {
//     id: '3',
//     title: "IT ARCHITECTS",
//     content: "For IT architects, our solution offers a scalable and flexible infrastructure that adapts to the organization's needs. We provide deployment options in both cloud and on-premises environments, allowing for hybrid or fully cloud-based implementation, depending on specific requirements. Our platform also offers security options and access control, ensuring the protection of sensitive data and models. Additionally, our solution integrates with existing tools and technologies, facilitating adoption and integration into the organization's IT ecosystem.",
// },