import "./buttons.css";
import React from "react";


const Buttons = ({ buttonText }) => {

    return(
        <div>
            <button className="Page-buttons"> {buttonText} </button>
        </div>
    )
    
};



export default Buttons;