import "./roles.css";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rightarrow from "../../assets/images/Rightarrow.svg";
import RolesCards from "./Rolescard";
import Rolelist from "../../content/roleslist";


const Roles = () => {
  const [showSlider, setShowSlider] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setShowSlider(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000, 
    prevArrow: <></>,
    nextArrow: <img className="slick-next" src={Rightarrow} alt="Next" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="SolutionPage" className="Roles-Section">
      <div className="Roles-container">
        <div className="Roles-title">
          <h2>Find the fit <span>for your role</span></h2>
        </div>
        {showSlider ? (
          <div className="Slider-container">
            <Slider {...sliderSettings}>
              {Rolelist.map((role, index) => (
                <div key={index} className="RolesCards">
                  <RolesCards role={role} />
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <div className="RoleCards-container">
            {Rolelist.map((role, index) => (
              <div key={index} className="RolesCards">
                <RolesCards role={role} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Roles;
