const ApisList = [

    {
        id: '1',
        title: 'INTEGRATE',
        image: require("../assets/images/Apis/integrate.png"),
        content:'Effortless integration establishment between your machine learning models & databases and our platform. Take advantage of pre-built connectors tailored for fast integration with popular software solutions.'
    },
    {
        id:'2',
        title:'EXPERIMENT',
        image: require("../assets/images/Apis/experiment.png"),
        content:"Accelerate your machine learning experimentation process with us. Leverage our platform's capabilities to easily test and iterate on different models and configurations. Gain valuable insights and make informed decisions to improve your ML workflows."
    },
    {
        id:'3',
        title:'DEPLOY',
        image: require("../assets/images/Apis/deploy.png"),
        content:'Execute machine learning workloads at scale, anywhere you need them.You can run your models in the cloud, on-premises, in a hybrid environment, or even at the edge. We provide flexibility , scalability and accessibility to suit your specific needs through reliable APIs.'
    },
    {
        id:'4',
        title:'MONITOR',
        image: require("../assets/images/Apis/monitor.png"),
        content:'Ensure the performance and reliability of your deployed models with our platform comprehensive monitoring capabilities. Gain real-time insights into model behavior, resource utilization, and data quality. Receive alerts and notifications to proactively address any issues and optimize your ML deployments.'
    }

]

export default ApisList;