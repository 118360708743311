import './popup.css'
import React from "react";
import { Modal } from "reactstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import PopUpsList from '../../content/SolutionList';

function PopUps({ selectedPopupId, onClose }) {
  const modalStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 100,
    width: window.innerWidth <= 790 ? "80%" : "auto",
  };

  const selectedPopup = PopUpsList.find(popup => popup.id === selectedPopupId);

  if (!selectedPopup) return null;

  return (
    <Modal isOpen={true} style={modalStyles} toggle={onClose} backdrop={true}>
      <div className="PopUp-Header">
        <h2>{selectedPopup.title}</h2>
        <AiOutlineCloseCircle className='Close-ButtonPopup' onClick={onClose} />
      </div>
      <div className="PopUp-Body">
        <p dangerouslySetInnerHTML={{ __html: selectedPopup.content }}></p>
      </div>
    </Modal>
  );
}

export default PopUps;

