import "./contact.css";
import React, { useState, useEffect } from "react";
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import { animateScroll as scroll } from "react-scroll";
import Image from "../../assets/images/LogoFooter.png";
import Linkedin from "../../assets/images/Footer/linkedin.png";
import Component from "../../assets/images/Footer/Component.png";
import Crunchbase from "../../assets/images/Footer/crunchbase.png";
import PopUps from "../Popup";
import SuccessPopUp from "./SuccessPopUp";
import { ClipLoader } from "react-spinners";


const Contact = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(true);
  const [showError, setShowError] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const hasMissingFields = missingFields.length > 0;
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const [areInputsFilled, setAreInputsFilled] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [showPopUp, setShowPopUp] = useState(false);
  const [selectedPopupId, setSelectedPopupId] = useState(null);

  const togglePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  const scrollToHome = () => {
    scroll.scrollTo(document.getElementById("Home").offsetTop);
  };

  const scrollToProject = () => {
    scroll.scrollTo(document.getElementById("ProductPage").offsetTop);
  };

  const scrollToSolution = () => {
    scroll.scrollTo(document.getElementById("SolutionPage").offsetTop);
  };

  const scrollToApi = () => {
    scroll.scrollTo(document.getElementById("ApiPage").offsetTop);
  };

  const scrollToApiSection = (id) => {
    const element = document.getElementById(`api-${id}`);
    if (element) {
        scroll.scrollTo(element.offsetTop);
    }
};

  const scrollToIntegrations = () => {
    scroll.scrollTo(document.getElementById("IntegrationsPage").offsetTop);
  };

  const scrollToWho = () => {
    scroll.scrollTo(document.getElementById("whoPage").offsetTop);
  };

  const scrollToWhoAbout = () => {
    scroll.scrollTo(document.getElementById("whoPage").offsetTop);
  };

  const scrollToWhoMission = () => {
    scroll.scrollTo(document.getElementById("MisionAndVision").offsetTop);
  };

  const handleClickHome = () => {
    scrollToHome();
  };

  const handleClickProduct = () => {
    scrollToProject();
  };

  const handleShowPopup = (id) => {
    scrollToSolution();
    setSelectedPopupId(id);
    setShowPopUp(true);
  };

  const handleClosePopup = () => {
    setShowPopUp(false);
    setSelectedPopupId(null);
  };

  const handleClickApi = () => {
    scrollToApi();
  };

  const handleClickIntegrations = () => {
    scrollToIntegrations();
  };

  const handleClickWho = () => {
    scrollToWho();
  };

  const isFormValid = email.trim() !== "" && message.trim() !== "";
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
//--------HandleSubmit Original---------------------------------------------------------


  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid && isValidEmail && recaptchaResponse !== "") {
      setLoading(true);
      const templateParams = {
        from_email: email, 
        message: message,
        to_email: "francisco.andrade@convolution-ai.com, paz.andrade@convolution-ai.com, franco.tesei@convolution-ai.com, michelle.lenoir@convolution-ai.com",
        subject: "Consulta web"
      };

      emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
       )
      .then((response) => {
        console.log('Correo enviado exitosamente!', response.status, response.text);
        setLoading(false);
        setShowSuccessPopup(true);
        setEmail("");
        setMessage("");
        setMissingFields([]);
        setShowError(false);
       }, (error) => {
        console.error('Error al enviar el correo:', error);
        setLoading(false);
       });
     } else {
        setShowError(true);
        setMissingFields([
         ...(email.trim() === "" ? ["email"] : []),
         ...(message.trim() === "" ? ["message"] : []),
         ...(recaptchaResponse === "" ? ["recaptcha"] : []),
       ]);
    }
  };

  //------------Para prueba en Local Host SIN RECAPTCHA-----------------


  //  const handleSubmit = (e) => {
  //    e.preventDefault();
  
  //    if (isFormValid && isValidEmail) {
  //      setLoading(true);
  //      const templateParams = {
  //        from_email: email,
  //        message: message,
  //        to_email: "francisco.andrade@convolution-ai.com",
  //        subject: "Consulta web"
  //      };
  
  //      emailjs.send(
  //        process.env.REACT_APP_SERVICE_ID,
  //        process.env.REACT_APP_TEMPLATE_ID,
  //        templateParams,
  //        process.env.REACT_APP_USER_ID
  //      )
  //      .then((response) => {
  //        setLoading(false);
  //        setShowSuccessPopup(true);
  //        setEmail("");
  //        setMessage("");
  //        setMissingFields([]);
  //        setShowError(false);
  //      }, (error) => {
  //        console.error('Error al enviar el correo:', error);
  //        setLoading(false);
  //      });
  //    } else {
  //      setShowError(true);
  //      setMissingFields([
  //        ...(email.trim() === "" ? ["email"] : []),
  //        ...(message.trim() === "" ? ["message"] : []),
  //      ]);
  //    }
  //  };
  

  const handelClickOpenPopUp = () => {
    setShowSuccessPopup(true);
  };

  const handleClickLinkedIn = () => {
    window.open("https://www.linkedin.com/company/convolutionai/about/");
  };

  const handleClickCrunchBase = () => {
    window.open("https://www.crunchbase.com/organization/convolution-ai");
  };

  const handleCloseSuccessPopup = () => {
    setShowSuccessPopup(false);
    setIsSuccessPopupVisible(true);
  };

  const checkInputsFilled = () => {
    const inputsFilled = email.trim() !== "" && message.trim() !== "";
    setAreInputsFilled(inputsFilled);
  };

  useEffect(() => {
    checkInputsFilled();
  }, [email, message]);

  const adjustTextareaHeight = () => {
    const textarea = document.getElementById("message");
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  };
  
  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);
  
  const handleTextareaChange = (e) => {
    setMessage(e.target.value);
    adjustTextareaHeight();
  };

  return (
    <div id="contactPage" className="contact">
      <div className="contact-container">
        <div className="ContactTitlecontainer">
          <h2>Contact <span>us</span></h2>
          <p>
            For any inquiries, partnership opportunities, or to learn more about our AI solutions, feel free to reach
            out.
            <br />
            <br />
            We look forward to hearing from you and assisting you on your AI journey.
          </p>
        </div>

        <form className="form-contact" onSubmit={handleSubmit}>
          <div className={`input-container ${hasMissingFields && "error"}`}>
            <input
              placeholder="E-MAIL"
              className="input-email"
              type="email"
              id="email"
              minLength='10'
              required={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={`input-container ${hasMissingFields && "error"}`}>
            <textarea
              className={`textarea-m ${hasMissingFields && "error"}`}
              placeholder="MESSAGE"
              id="message"
              required={true}
              value={message}
              minLength="5"
              onChange={handleTextareaChange}
            />
          </div>

          {message.length > 4 && isSuccessPopupVisible && (
            <div className="recaptcha-container">
              <ReCAPTCHA
                sitekey="6LcxGxMnAAAAAN7rV6q-78qHQU76nTlJmWJRhx-A"
                onChange={(value) => setRecaptchaResponse(value)}
              />
            </div>
          )}

          {hasMissingFields && (
            <div className="recaptcha-error">
              {missingFields.includes("recaptcha")
                ? "Please complete the reCAPTCHA."
                : "Please fill in all required fields."}
            </div>
          )}

          <div className="submitbutton-pos">
            {!loading ? (
              <button className="button-submition" disabled={!isFormValid}>
                SUBMIT
              </button>
            ) : (
              <span className="button-Loading">
                
                <ClipLoader color="#ffffff" loading={loading} size={20} />
              
              </span>
            )}
          </div>
        </form>

        {showSuccessPopup && (
          <SuccessPopUp onClose={handleCloseSuccessPopup} />
        )}
      </div>

      <footer className="Footercontact">
        <img onClick={handleClickHome} className="Footer-logo" src={Image} alt="" />

        <div className="allsections">
          <div className="footer-section">
            <h2 onClick={handleClickProduct}>PRODUCT</h2>
          </div>

          <div className="footer-section">
            <h2 onClick={scrollToSolution}>SOLUTION</h2>

            <ul className="footer-list">
              
              <li onClick={() => handleShowPopup("1")}>Data Engineers </li>
              
              <li onClick={() => handleShowPopup("2")}>Developer</li>
                
              <li onClick={() => handleShowPopup("3")}>It Architects</li>
                
              <li onClick={() => handleShowPopup("4")}>Data Scientist</li>
              
              <li onClick={() => handleShowPopup("5")}>Executives</li> 
              
            </ul>
          </div>
            {showPopUp && <PopUps selectedPopupId={selectedPopupId} onClose={handleClosePopup} />}
          <div className="footer-section">
            <h2 onClick={handleClickApi}>API</h2>

            <ul className="footer-list">
              <li onClick={handleClickApi}>Integrate</li>
              <li onClick={() => scrollToApiSection(1)}>Experiment</li>
              <li onClick={() => scrollToApiSection(2)}>Deploy</li>
              <li onClick={() => scrollToApiSection(3)}>Monitor</li>
            </ul>
          </div>

          <div className="footer-section">
            <h2 onClick={handleClickIntegrations}>INTEGRATE</h2>
          </div>

          <div className="footer-section">
            <h2 onClick={handleClickWho}>WHO WE ARE?</h2>

            <ul className="Who-list">
              <li onClick={scrollToWhoAbout}>About Us</li>
              <li onClick={scrollToWhoMission}>Mission and Vision</li>
            </ul>
          </div>
        </div>

        <div className="icon-container-footer">
          <img onClick={handleClickLinkedIn} className="Footer-icon" alt="" src={Linkedin} />
          <img className="Footer-icon" alt="" src={Component} />
          <img onClick={handleClickCrunchBase} className="Footer-icon" alt="" src={Crunchbase} />
          <p>info@convolution-ai.com</p>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
