import "./landing.css";
import React from "react";
import Buttons from "../Buttons";
import { animateScroll as scroll } from "react-scroll";

const Landing = () => {

    const scrollToContact = () => {
        scroll.scrollTo(document.getElementById("contactPage").offsetTop);
      };

    return(
        <div className="Landing-container">
            <div className="information-container">
                <h1 className="Title">
                    <span>Maximize</span> the
                    <br/>
                    value of your
                    <br/>
                    Data & AI products
                </h1>

                <p>

                    With <span style={{ color: 'rgb(255, 159, 28)'}}>ConvolutionAI</span>, streamline your entire 
                    <br/>
                    data journey with our unified self-service
                    <br/>
                    platform. Reduce complexity and manual  
                    <br/>
                    effort while empowering everyone with
                    <br/> 
                    easy data access.
                </p>
                <div onClick={scrollToContact} className="ButtonLanding">
                    <Buttons  buttonText="START TODAY"/>
                </div>
            </div>
        </div>
    )
};



export default Landing;